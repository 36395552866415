import {

	Message
} from 'element-ui'
import {
	handlePageQuery,
	handleQueryTeamOfMatchJoin,
	handleJoinMatchDownload
} from '@index/api/mymatchs/scoretable';
import matchtitle from '../../components/matchtitle';
export default {
	name: 'onlinesignuptable',
	components: {
		matchtitle
	},
	data() {
		return {
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: {},
			tableData: [],
			teamsInfo: [],
			matchId: sessionStorage.getItem("joinMatchId"),
		};
	},
	watch: {

	},
	created() {
		this.pageQuery();
		this.queryTeamOfMatchJoin();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQuery({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con: this.queryForm.con,
				team:this.queryForm.team,
				matchId: this.matchId,
				userType:'1',
				sortWay: [{
					fieldName: 'orderNo',
					sortWay: 'ASC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					})
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		queryTeamOfMatchJoin() {
			handleQueryTeamOfMatchJoin(this.matchId).then(res => {
				if (res.status == 200) {
					this.teamsInfo = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		download() {
			var requestParams={...this.queryForm,matchId:this.matchId};
			handleJoinMatchDownload(requestParams).then(res => {
				var fileName = "成绩表.xlsx";
				var blob = new Blob([res], {
					type: "application/octet-stream"
				});
				if (window.navigator.msSaveOrOpenBlob) { //msSaveOrOpenBlob方法返回bool值
		
					navigator.msSaveBlob(blob, fileName); //本地保存
				} else {
		
					var link = document.createElement('a'); //a标签下载
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					window.URL.revokeObjectURL(link.href);
				}
			});
		
		}

	}
}
