import request from '@index/utils/request'

export function handlePageQuery(data) {
  return request({
    url: 'scoreTable/pageScores',
    method: 'post',
    data
  })
}


export function handleDeleteScoreTable(matchId) { //删除成绩表
  return request({
    url: 'scoreTable/deleteScoreTable',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}


export function handleQueryTeamOfMatch(matchId) { //查询比赛的代表队
  return request({
    url: 'scoreTable/queryTeamOfMatch',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}


export function handleQueryTeamOfMatchJoin(matchId) { //查询参赛方比赛的代表队
  return request({
    url: 'scoreTable/queryTeamOfMatchJoin',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}




export function handleJoinMatchDownload(data) { //参赛方下载成绩表
  return request({
    url: 'scoreTable/joinMatchDownload',
    method: 'post',
	responseType: 'blob',
    data
  })
}
